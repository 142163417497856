export function frontendLink(url: string) {
	return url.replace(
		process.env.NEXT_PUBLIC_WORDPRESS_URL,
		`${process.env.NEXT_PUBLIC_FRONTEND_URL}/`
	);
}

export function formatDate(dateString: string) {
	// Create a Date object from the string
	const date = new Date(dateString);

	// Options for formatting the date
	const options: Intl.DateTimeFormatOptions = {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
	};

	// Format the date using Intl.DateTimeFormat
	return new Intl.DateTimeFormat('en-US', options).format(date);
}

export function updateUrls(obj: any, oldUrl: string, newUrl: string) {
	for (let key in obj) {
		if (typeof obj[key] === 'string' && obj[key].includes(oldUrl)) {
			obj[key] = obj[key].replace(oldUrl, newUrl);
		} else if (typeof obj[key] === 'object' && obj[key] !== null) {
			updateUrls(obj[key], oldUrl, newUrl);
		}
	}
}

export function htmlDecode(input: string) {
	var e = document.createElement('div');
	e.innerHTML = input;
	return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
}
