import { Box, Heading, Stack } from '@chakra-ui/react';
import { useBlocksTheme } from '@faustwp/blocks';
import { FC } from 'react';
import { noto_sans } from '../../fonts';
import { CBATheme } from '../../types/Theme';

type StepDisplayProps = {
	title: string;
	heading: string;
	content: string;
	variant: string;
};

const StepDisplay: FC<StepDisplayProps> = props => {
	if (props.variant === 'orange') {
		return <OrangeDisplay {...props} />;
	}
	if (props.variant === 'stacked') {
		return <StackedDisplay {...props} />;
	}

	return <></>;
};

const OrangeDisplay: FC<StepDisplayProps> = ({ title, heading, content }) => {
	const theme = useBlocksTheme() as unknown as CBATheme;

	return (
		<Box
			textAlign="center"
			boxShadow="sm"
			rounded={12}
			minWidth="314px"
			sx={{
				h3: {
					color: '#ffffff !important',
					my: '0 !important',
				},
			}}
			mx={{
				base: 0,
				lg: 4,
			}}
		>
			{title && (
				<Heading
					as="h3"
					bg={theme.palette.secondary}
					color="white"
					fontFamily={theme.fontFamilies.body}
					fontSize="1.58rem"
					textAlign="center"
					py={3}
					borderTopRadius={12}
					dangerouslySetInnerHTML={{ __html: title }}
				/>
			)}
			<Box
				px={7}
				pt={6}
				pb={7}
				sx={{
					h4: {
						my: '0 !important',
					},
				}}
			>
				{heading && (
					<Heading
						as="h4"
						color={theme.palette.primary}
						fontWeight={700}
						fontFamily={noto_sans.style.fontFamily}
						fontSize={theme.fontSizes.medium}
						px={8}
						dangerouslySetInnerHTML={{
							__html: heading,
						}}
					/>
				)}
				{content && (
					<Box
						mt={4}
						dangerouslySetInnerHTML={{
							__html: content,
						}}
						fontFamily={noto_sans.style.fontFamily}
					/>
				)}
			</Box>
		</Box>
	);
};

const StackedDisplay: FC<StepDisplayProps> = ({ title, heading, content }) => {
	const theme = useBlocksTheme() as unknown as CBATheme;

	return (
		<Stack
			direction={{
				base: 'column',
				lg: 'row',
			}}
			align="center"
			gap={4}
			bg="white"
			p={4}
			borderRadius={8}
			boxShadow="lg"
		>
			{title && (
				<Box
					color={theme.palette.secondary}
					fontWeight="bold"
					fontSize={theme.fontSizes.xxLarge}
					px={8}
					dangerouslySetInnerHTML={{ __html: title }}
				/>
			)}
			<Box>
				{heading && (
					<Heading
						as="h3"
						fontSize={theme.fontSizes.medium}
						color={theme.palette.primary}
						dangerouslySetInnerHTML={{ __html: heading }}
					/>
				)}
				{content && (
					<Box mt={2} dangerouslySetInnerHTML={{ __html: content }} />
				)}
			</Box>
		</Stack>
	);
};

export default StepDisplay;
